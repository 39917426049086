export function CheckImgExists(imgurl) {
    var ImgObj = new Image() // 判断图片是否存在
    ImgObj.src = imgurl
    console.log("CheckImgExists imgurl: " + imgurl)
    // 存在图片
    if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        console.log("CheckImgExists true")
        return true
    } else {
        console.log("CheckImgExists false")
        return false
    }
}
