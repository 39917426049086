<template>
  <HelloWorld msg="Spotify杂货铺"/>

    <div id="con" style="margin:0 auto;width: 300px">
      <el-col :span="howWidth" v-for="(item, index) in tableArr" :key="index">
        <div v-if="item.value !== null && item.value !== '' && item.key !=='购买记录'" class="box" style="width: 300px; ">
          <div class="content1" >{{ item.key }}</div>
          <div class="content2">{{ item.value == "" ? "待完善" : item.value }}</div>
        </div>
        <div v-if="item.key ==='购买记录'" class="box" style="width: 300px; ">
          <div class="content1" >{{ item.key }}</div>
          <div class="content2"><el-button type="plain" size="small" style="margin-top: 10px" @click="routerpush()">查询记录</el-button></div>
        </div>
      </el-col>

    </div>
    <div>


  </div>
  <div class="demo-image">
    <div v-for="(fit , index) in fits" :key="fit" class="block">
      <span class="demonstration">{{ fit }}</span>
      <el-image style="width: 100px; height: 100px;" :src=" url[index] " />
    </div>
  </div>
  <h3>长按付款码保存,付款<span class="highlight">5分钟内</span>点击续费按钮</h3>
  <el-button :disabled="isButtonDisabled" type="primary" style="margin-top: 10px" @click="toastUser()">{{ billBtn }}</el-button>
  <h2> {{ billStatusTitle }} </h2>
</template>

<script>
import HelloWorld from '../components/HelloWorld.vue'
import axios from 'axios'
import {CheckImgExists} from "@/utils";
import {TYPE, useToast} from "vue-toastification";
import router from "@/router";
//import route from "@/router";
const toast = useToast();

export default {
  data() {
    return {
      fits : ['微信付款码', '支付宝付款码'],
      url : [],
      tableArr: [
        {
          key: "账单类型",
          value: "未知",
        },
        {
          key: "商品数量",
          value: 0,
        },
        {
          key: "邮箱地址",
          value: "xxxx@xxx.xxx",
        },
        {
          key: "下次续费",
          value: "2023-01-10",
        },
        {
          key: "续费金额",
          value: "28",
        },
        {
          key: "续费周期",
          value: "未知",
        },
        {
          key: "帐号",
          value: "未知",
        },
        {
          key: "密码",
          value: "未知",
        },
        {
          key: "备注名(位置)",
          value: "未知",
        },
        {
          key: "购买记录",
          value: "https://w.xinsi.us"
        }
      ],
      howWidth: 6,
      wechat_code: "",
      alipay_code:"",
      onCarId: 0,
      isButtonDisabled: true,
      billBtn: "点击续费",
      billStatusTitle: "",
      uuid: null
    }
  },
  name: 'App',
  computed() {
  },
  created() {
    this.getUserDetails();
  },
  methods: {
    toastUser() {
      console.log("toastUser: " + this.uuid);
      //http://localhost:8010 or http://localhost:8013 https://api.xinsichen.com
      axios.post("https://api.xinsichen.com/api/search/confirmRefill", { "uuid": this.uuid}).then(res=> {
        if (res.data.status == 'SUCCESS' ) {
          this.billBtn = "处理中";
          this.isButtonDisabled = true
          this.billStatusTitle = "36h内如还未处理，请联系管理员!!"
          toast("已续费成功,请耐心等待管理员处理");
        } else {
          toast("系统异常，请及时联系管理员！！！");
        }
      }).catch(error => {
        console.log("error: " + error)
        toast("系统异常，请及时联系管理员！！！");
      })
    },
    routerpush() {
      router.push({
        path: '/records',
        query: { id : this.onCarId}
      })
    },
    getUrlParam(param) {
      // 获取由全部参数组成的字符串。
      let query = window.location.search.substring(1);
      // 分割参数，得到每一个参数字符串组成的数组。
      let vars = query.split('&');
      // 遍历数组，得到每一个参数字符串。
      for (let i = 0; i < vars.length; i++) {
        // 分割每一个参数字符串，得到参数名和参数值组成的数组。
        var pair = vars[i].split('=');
        // 如果参数名等于传入的param，则返回该值。
        if (pair[0] == param) {
          return decodeURI(pair[1]);
        }
      }
      // 若参数不存在，则返回false。
      return false;
    },
    getUserDetails() {
     //let id = this.router.currentRoute.value.query.id;
      this.uuid = this.getUrlParam('id')
      //http://localhost:8010 or http://localhost:8013 https://api.xinsichen.com
      axios.get("https://api.xinsichen.com/api/search/getUserAndGoodsInfo", { params: {UUID: this.uuid} }).then(res=> {
       let details = res.data.data;
       //console.log("details: " + JSON.stringify(details))
       let goodsType = details.onCarList[0].goodsIdType;
       let debitType = details.onCarList[0].debitType;
       let payAmount = details.onCarList[0].payAmount;
       this.onCarId = details.onCarList[0].id;
       this.tableArr[0].value = goodsType;
       this.tableArr[1].value = details.onCarList.length;
       this.tableArr[2].value = details.userInfo.email;
       this.tableArr[3].value = details.onCarList[0].nextPayTime;
       this.tableArr[4].value = details.onCarList[0].payAmount;
       this.tableArr[5].value = details.onCarList[0].debitType;
       this.tableArr[6].value = details.onCarList[0].accoutId;
       this.tableArr[7].value = details.onCarList[0].goodsPassword;
       this.tableArr[8].value = "";
       let billStatus = details.onCarList[0].billStatus;

        let ct =  details.userInfo.contact;
        let ct_index = ct.lastIndexOf("-");
        if (ct_index > 0) {
          this.tableArr[8].value = ct.substring(ct_index + 1)
        }

        //console.log("res: " + details.onCarList[0].accoutId)

        //let lowerType =  goodsType.toLowerCase();

        //console.log("isExist: " + CheckImgExists("Wechat_Receipt_Code.jpg"));
        this.wechat_code = "./payment/wechat/" + goodsType.toLowerCase() + "/" + goodsType + "_" + debitType + "_" + payAmount + ".jpg";
        this.alipay_code = "./payment/alipay/" + goodsType.toLowerCase() + "/" + goodsType + "_" + debitType + "_" + payAmount + ".jpg";
        if (!CheckImgExists(this.wechat_code)) {
           this.wechat_code = "./payment/wechat/default/Wechat_Receipt_Code.jpg";
        }

        if (!CheckImgExists(this.alipay_code)) {
          this.alipay_code = "./payment/alipay/default/Alipay_Default_Code.jpg";
        }

        this.url[0] =  this.wechat_code;
        this.url[1] = this.alipay_code;

        let type = {type : TYPE.SUCCESS};
        //console.log("wechat_code: " + this.wechat_code + ", alipay_code: " + this.alipay_code);
        if (billStatus == 0) {
          this.billBtn = "处理中";
          this.isButtonDisabled = true;
          this.billStatusTitle = "36h内如还未处理，请联系管理员!!";
          type = {type : TYPE.INFO}
        } else if (billStatus == -1) {
          this.billBtn = "续费";
          this.isButtonDisabled = false;
          this.billStatusTitle = "续费失败，原因:未收到款，如已付款，请务必联系我";
          type = {type : TYPE.ERROR}
        } else {
          this.billBtn = "续费";
          this.isButtonDisabled = false;
          const future = new Date(details.onCarList[0].nextPayTime)
          const now = new Date();
          const timeDiff = future.getTime() - now.getTime();
          let days = 0;
          // 确保未来的日期不早于当前日期
          if (timeDiff >= 1) {
            days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          }

          if (days < 1) {
            this.billStatusTitle = "会员已过期,请及时续费";
            type = {type : TYPE.WARNING}
          } else {
            this.billStatusTitle = "会员有效期剩余" + days + "天";
            type = {type : TYPE.DEFAULT}
          }
        }
        toast(this.billStatusTitle, type);
       //tableArr[0].value = res.data.
      }).catch(error=> {
        console.log("err:  " + error)
      })
    },
  },
  components: {
    HelloWorld
  }
}
</script>
<style >
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
    .box {
      width: 100%;
      height: 40px;
      display: flex;
      border-left: 1px solid #e9e9e9;
      border-top: 1px solid #e9e9e9;

    }
    .content1 {
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #fafafa;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      font-size: 14px;
    }
    .content2 {
      width: 200px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #fff;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      color: #b0b0b2;
      font-size: 14px;
    }
  .el-col:nth-child(3n) {
  border-right: 1px solid #e9e9e9;
}
  .highlight {
    font-weight: bold; /* 加粗 */
    color: red;       /* 红色 */
  }
  .el-col:last-child {
    border-right: 1px solid #e9e9e9;
  }

  .demo-image .block {
    padding: 10px 0;
    text-align: center;
    /*border-right: solid 10px var(--el-border-color);*/
    display: inline-table;
    width: 20%;
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: top;
  }
  .demo-image .block:last-child {
    border-right: none;
  }
  .demo-image .demonstration {
    display: block;
    color: var(--el-text-color-secondary);
    font-size: 14px;
    margin-bottom: 10px;
  }


</style>

