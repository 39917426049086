<template>
  <div>
    <h1>仅显示最近三次的记录</h1>
  </div>
  <div id="con" style="margin:0 auto;width: 400px">
    <div v-if="tableArr.length == 0" class="box">
      <h3>该服务刚提供，后续都会有记录</h3>
    </div>
    <el-col  v-for="(item, index) in tableArr" :key="index">
      <div v-if="index == 0 && tableArr.length > 0" class="box">
        <div class="content1">续费时间</div>
        <div class="content5">金额</div>
        <div class="content3">订阅开始</div>
        <div class="content3">订阅结束</div>
      </div>
      <div v-if="tableArr.length > 0 && item !== null" class="box">
        <div class="content2">{{ item.renewTime }}</div>
        <div class="content6">{{ item.payAmount }}</div>
        <div class="content4">{{ item.started }}</div>
        <div class="content4">{{ item.expired }}</div>
      </div>
    </el-col>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      fits : ['微信付款码', '支付宝付款码'],
      url : [],
      tableArr: [
        /*{
          payAmount: 23,
          renewTime: "2023-06-30 06:51:15",
          debitTypeId: 3,
          started: "2023-06-30",
          expired: "2023-09-30"
        }*/
      ],
      wechat_code: "",
      alipay_code:"",
    }
  },
  name : 'OrderRecords',
  computed() {
  },
  created() {
    this.getOrderRecords();
  },
  methods : {
    getOrderRecords() {
      //let id = this.router.currentRoute.value.query.id;
      let id = this.getUrlParam('id')
      console.log("id: " + id)
      //http://localhost:8010 or http://localhost:8013 https://api.xinsichen.com
      axios.get("https://api.xinsichen.com/api/search/getOrderRecords", { params: {onCarId: id} }).then(res=> {
        this.tableArr = res.data.data;

        //console.log("this.tableArr", this.tableArr.length)
        // console.log("tableArr11:" + res.data.data[0].onCarId);
        //tableArr[0].value = res.data.
      }).catch(error=> {
        console.log("err:  " + error)
      })
    },
    getUrlParam(param) {
      // 获取由全部参数组成的字符串。
      let query = window.location.search.substring(1);
      // 分割参数，得到每一个参数字符串组成的数组。
      let vars = query.split('&');
      // 遍历数组，得到每一个参数字符串。
      for (let i = 0; i < vars.length; i++) {
        // 分割每一个参数字符串，得到参数名和参数值组成的数组。
        var pair = vars[i].split('=');
        // 如果参数名等于传入的param，则返回该值。
        if (pair[0] == param) {
          return decodeURI(pair[1]);
        }
      }
      // 若参数不存在，则返回false。
      return false;
    }
  }
}


</script>

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.box {
  width: 100%;
  height: 40px;
  display: flex;
  border-left: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;

}

.content5 {
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fafafa;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #333;
  font-size: 14px;
}

.content3 {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fafafa;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #333;
  font-size: 14px;
}

.content1 {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fafafa;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #333;
  font-size: 14px;
}
.content2 {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #b0b0b2;
  font-size: 14px;
}

.content4 {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #b0b0b2;
  font-size: 14px;
}

.content6 {
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  color: #b0b0b2;
  font-size: 14px;
}

.el-col:nth-child(3n) {
  border-right: 1px solid #e9e9e9;
}
.el-col:last-child {
  border-right: 1px solid #e9e9e9;
}

.demo-image .block {
  padding: 10px 0;
  text-align: center;
  /*border-right: solid 10px var(--el-border-color);*/
  display: inline-table;
  width: 20%;
  box-sizing: border-box;
  margin-right: 10px;
  vertical-align: top;
}
.demo-image .block:last-child {
  border-right: none;
}
.demo-image .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}


</style>