import {createRouter, createWebHistory} from "vue-router";
import details from "../views/Details.vue";
import records from "../views/RecordsOrder.vue";


const router = createRouter({
    history: createWebHistory(""),
    routes: [
        {
            path: '/records',
            name: 'records',
            component: records,
        },
        {
            path: '/',
            name: 'details',
            component: details,
        }
    ],
});

export default router;