<template>
  <div class="hello">
    <img alt="Vue logo" src="../assets/backgroud.png" width="300" height="150">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 1px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 1px;
}
a {
  color: #42b983;
}
/*.hello {

  background-image: url("../assets/backgroud.png");
  width: 30%;
  height: 40%;
  background-size: 30%;
}*/
</style>
